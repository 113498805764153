<template>
  <div id="header-three">
    <section class="bg-half-170 d-table w-100 overflow-hidden">
      <div class="container">
        <div class="row align-items-center pt-5">
          <div class="col-lg-7 col-md-6">
            <div class="title-heading">
              <SmartLabel tag="h1" className="heading mb-3" target="title" v-model="content.title"/>
              <SmartLabel tag="p" className="para-desc text-muted" target="subtitle" v-model="content.subtitle"/>
              <div class="mt-4 pt-2">
                <a href="https://1.envato.market/4n73n"
                  target="_blank"
                  class="btn btn-primary">
                  Buy Now
                  <span class="badge badge-pill badge-danger ml-2">v2.5</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="classic-saas-image position-relative">
              <div class="bg-saas-shape position-relative">
                <img src="images/saas/classic01.png" class="mx-auto d-block" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "HeaderThree",
  };
</script>